<template>
  <Layout>
    <PageHeader :title="title" :items="items" class="poppins fw-normal" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body table">
            <!-- <div
              class="
                d-flex
                justify-content-between
                flex-sm-nowrap flex-wrap
                gap-3
                my-3
              "
            >
              <div class="d-flex justify-content-between w-100">
                <button
                  type="submit"
                  class="btn btn-primary waves-effect waves-light py-2"
                >
                  Add Business
                </button>
                <div class="plan_filter pe-2 py-0">
                  <select
                    class="form-select table-light pe-5 py-75rem"
                    id="floatingSelectGrid"
                    aria-label="select"
                  >
                    <option selected="">All</option>
                    <option value="1">Accepted</option>
                    <option value="2">Rejected</option>
                    <option value="2">Pending</option>
                  </select>
                </div>
              </div>
              <div class="search position-relative min_200">
                <input
                  class="form-control ps-5 table-light border-0"
                  list="datalistOptions"
                  id="searchList"
                  placeholder="Type to search..."
                />
                <i class="uil-search position-absolute top-0 px-3"></i>
              </div>
            </div> -->
            <div class="table-responsive">
              <table
                id="datatable"
                class="table table-centered table-nowrap mb-0 w-100"
              >
                <thead class="table-light">
                  <tr>
                    <th style="width: 20px"></th>
                    <th>Business Name</th>
                    <th>User Name</th>
                    <th>Contact Number</th>
                    <th>Website</th>
                    <th>Profile Link</th>
                    <th>Request On</th>
                    <th>Attachment</th>
                    <!-- <th>Approved On</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr colspan="8" v-if="loading">
                    <td colspan="9">
                      <APILoader
                        :loading="loading"
                        class="align-middle text-center"
                      ></APILoader>
                    </td>
                  </tr>
                  <tr v-else v-for="(claim, index) in claims" :key="index">
                    <th style="width: 20px"></th>
                    <td>
                      <span>{{ claim.vendorlisting.title }}</span>
                    </td>
                    <td>
                      <div class="userDetail">
                        <div class="d-flex align-items-center">
                          <div v-if="claim.user">
                            <img v-if="!claim.user.avatar"
                            src="../../../../../public/avatar.jpg"
                            class="user-img me-2"
                            alt="..."
                            width="30px"
                            height="30px"
                          />
                          <img v-else
                          :src="claim.user.avatar"
                            class="user-img me-2"
                            alt="..."
                            width="30px"
                            height="30px"
                          />
                          </div>
                          <span>{{ claim.user.full_name }}</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      {{ claim.contact_name }}
                    </td>
                    <td>
                      <a href="" class="text-dark pe-1">{{ claim.website }} </a>
                    </td>
                    <td>
                      <a href="" class="text-dark pe-1">
                        {{ claim.linkedin }}</a
                      >
                    </td>
                    <td class="text-center">{{ claim.created_at }}</td>
                    <td>
                      <a
                        @click="claimAttachments(claim.media)"
                        href="javascript: void(0);"
                        v-b-modal.modal-3
                        >View Attachments</a
                      >
                    </td>
                    <!-- <td class="text-center">{{ c }}</td> -->
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end table-responsive -->
          </div>
        </div>
      </div>
    </div>
    <Attachments :media="media" />
  </Layout>
</template>
<script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import Attachments from "../../../../components/attachments.vue";
import appConfig from "@/app.config";
import $ from "jquery";

export default {
  page: {
    title: "Claim Business",
    meta: [
      {
        name: "approved",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Attachments,
  },
  data() {
    return {
      loading: true,
      media: [],
      title: "Approved Claims",
      claims: null,
      items: [
        {
          text: "TipShare",
        },
        {
          text: "Claim Business",
          active: true,
        },
      ],
    };
  },
  mounted() {
    this.allAcceptedClaims();
  },
  methods: {
    randerData(response) {
      // console.log(response.data.data);
      this.claims = response.data.data;
      this.loading = false;
      if ($.fn.dataTable.isDataTable("#datatable")) {
        let table = $("#datatable").DataTable();
        table.destroy();
      }
      setTimeout(() => {
        $("#datatable").DataTable({
          scrollX:true,
        fixedHeader:{
            header: true,
            footer: true
        },
          ordering: false,
          lengthMenu: [
            [5, 10, 25, 50, -1],
            [5, 10, 25, 50, "All"],
          ],
          pageLength: 25,
        });
      });
    },
    allAcceptedClaims() {
      this.$axios
        .get("all-accepted-claims")
        .then((response) => {
          setInterval(this.randerData(response), 1500);
          //   this.claims = response.data.data.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    claimAttachments(data) {
      this.media = data;
      console.log(data);
    },
  },
};
</script>